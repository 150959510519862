'use strict'

function viewMoreLess (options) {
    var itemsToShow = 3;
    var $listItems = options;
    var totalItems = $listItems.length;
    if(totalItems>itemsToShow){ 
        let filterBarOptions = $listItems.find('li:nth-child(1)').nextAll();
        if (filterBarOptions.is(':visible')) {
            $('.show-moreLessRefinement').text('Ver mais +');
        } else {
            $('.show-moreLessRefinement').text('Ver menos -');
        }
        filterBarOptions.toggle()
    }
}

$(document).on('click', '.show-moreLessRefinement', function (event) {
    let filterBar = $(event.target)
    let filterBarOptions = filterBar.siblings('.values').find('li:nth-child(1)').nextAll();

    if (filterBarOptions.is(':visible')) {
        filterBar.text('Ver mais +');
    } else {
        filterBar.text('Ver menos -');
    }
    filterBarOptions.toggle(500);
});

$(document).ready(function () {
    var filterValue = $('.filter-value').length;
    var searchText = $('.search-result-category');
    if (filterValue > 0) {
        searchText.addClass('d-none');
    }
    let filterValues = $('.filter-valueComponent li') ;
    filterValues.length ==0 ? $('.filter-bar,.reset-filterButtonMobile').addClass('d-none') 
    && $('.reset-filterButtonMobile').removeClass('d-block'):$('.filter-bar,.reset-filterButtonMobile').removeClass('d-none');
    viewMoreLess ($('.categories-bar'));
    viewMoreLess ($('.price-bar'));
});
