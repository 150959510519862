const collapseBtn = document.querySelectorAll('.card-faq-js');
const collapseContent = document.querySelectorAll('.faq-arrow-js');
const collapseContentCard = document.querySelectorAll('.card-header-faq');

collapseBtn.forEach((element, index) => element.addEventListener('click', function () {
    collapseContent[index].classList.toggle('active');
    collapseContentCard[index].classList.toggle('active');

    collapseContent.forEach(function (content, currentIndex) {
        if (currentIndex !== index) {
            content.classList.remove('active');
        }
    });

    collapseContentCard.forEach(function (content, currentIndex) {
        if (currentIndex !== index) {
            content.classList.remove('active');
        }
    });
}));

function validarInput(input) {
    var regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(input.value)) {
        input.value = input.value.replace(/[^a-zA-Z\s]/g, '');
    }
}

document.getElementById('inputNome').addEventListener('input', function() {
    validarInput(this);
});

function validarEmail(email) {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

document.getElementById('inputEmail').addEventListener('input', function() {
    validarEmail(this)
});