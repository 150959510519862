$('#categoryMobile').on('click', function () {
    if ($('.arrow-category').hasClass('right')) {
        $('.arrow-category').removeClass('right');
        $('.arrow-category').addClass('down');
        $('.menu-item-content').addClass('active')
    } else {
        $('.arrow-category').removeClass('down');
        $('.arrow-category').addClass('right');
        $('.menu-item-content').removeClass('active')
    }
});
