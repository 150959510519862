'use strict';

var cart = require('../cart/cart');

var updateMiniCart = true;

module.exports = function () {
    cart();


    $('body').on('cart:closeMiniCart', function(){ 
        $('.minicartPosition').removeClass('show')
    })
    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('body').on('click tap touchstart', '.minicart', function (e) {
        // if ($('.search:visible').length === 0) {
        //     return;
        // }
        e.stopPropagation()

        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if ($('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }

            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }

        $('body').on('click', '.closeIconBox', function (e) {
            e.stopPropagation();
            $('.minicart .popover').removeClass('show');
        });
        $('body').on('click', function(e){ 
            $('.minicart .popover').removeClass('show');
        })


        $('.decreaseAmountMiniCart').off('click').on('click', function () {
            $('.remove-product-img, .productCard-totalPrice, .minicartQtt, .productCard-name, .productCard-Image').css('opacity', '0.5');
            $('.decreaseAmountMiniCart, .increaseAmountMiniCart').css('pointer-events', 'none');
            var $input = $(this).parent().find('input');
            var count = parseInt($input.val()) - 1;
            count = count < 1 ? 1 : count;
            $input.val(count);
            $input.change();
            setTimeout(function () {
                $('.decreaseAmountMiniCart, .increaseAmountMiniCart').css('pointer-events', 'auto');
                $('.remove-product-img, .productCard-totalPrice, .minicartQtt, .productCard-name, .productCard-Image').css('opacity', '1');
            }, 500);
            return false;
        });
        $('.increaseAmountMiniCart').off('click').on('click', function () {
            $('.remove-product-img, .productCard-totalPrice, .minicartQtt, .productCard-name, .productCard-Image').css('opacity', '0.5');
            $('.decreaseAmountMiniCart, .increaseAmountMiniCart').css('pointer-events', 'none');
            var $input = $(this).parent().find('input');
            $input.val(parseInt($input.val()) + 1);
            $input.change();
            setTimeout(function () {
                $('.decreaseAmountMiniCart, .increaseAmountMiniCart').css('pointer-events', 'auto');
                $('.remove-product-img, .productCard-totalPrice, .minicartQtt, .productCard-name, .productCard-Image').css('opacity', '1');
            }, 500);
            return false;
        });

    });

    $('.minicart').on('focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'focusout' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
        $('.minicart').trigger('click');
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};

function handleBodyOverflow() {
    var minicartPosition = document.querySelector('.minicartPosition');
    var windowSize = window.innerWidth;

    if (minicartPosition.classList.contains('show') && windowSize < 683) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
}

handleBodyOverflow();

setInterval(handleBodyOverflow, 200);