var toggleNewPassword = document.querySelector('#toggleNewPassword');
var newPassword = document.querySelector('#newPassword');

toggleNewPassword.addEventListener('click', function (e) {
    const type = newPassword.getAttribute('type') === 'password' ? 'text' : 'password';
    newPassword.setAttribute('type', type);
});

var toggleNewPasswordConfirm = document.querySelector('#toggleNewPasswordConfirm');
var newPasswordConfirm = document.querySelector('#newPasswordConfirm');

toggleNewPasswordConfirm.addEventListener('click', function (e) {
    const type = newPasswordConfirm.getAttribute('type') === 'password' ? 'text' : 'password';
    newPasswordConfirm.setAttribute('type', type);
});

var imagens = document.querySelectorAll('.eyeIconNewPass');
imagens.forEach(function (imagem) {
    imagem.addEventListener('click', function () {
        var imagemAtual = this.src;
        if (imagemAtual.endsWith('eyeIcon.svg')) {
            this.src = 'https://bjjr-004.dx.commercecloud.salesforce.com/on/demandware.static/Sites-GoPharma-Site/-/default/dwb005bf3a/images/eyeIconOff.svg';
            this.alt = 'Esconder senha'; 
            this.class = 'eyeIconOff';
            this.classList.remove('eyeIconNewPass');
            this.classList.add('eyeIconOff');
        } else {
            this.src = 'https://bjjr-004.dx.commercecloud.salesforce.com/on/demandware.static/Sites-GoPharma-Site/-/default/dwc327df1a/images/eyeIcon.svg';
            this.alt = 'Mostrar senha';
            this.class = 'eyeIconNewPass';
            this.classList.remove('eyeIconOff');
            this.classList.add('eyeIconNewPass');
        }
    });
});