window.jQuery = window.$ = require('jquery');
var processInclude = require('./util');

// external
import "slick-carousel";

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/categoryHeader'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('./components/searchResults'));
    processInclude(require('./components/cepModal'));
    processInclude(require('./components/slickCarousel'));
    processInclude(require('./components/showPassword'));
    processInclude(require('./components/faq'));
});

require('./thirdParty/bootstrap');
require('./components/spinner');