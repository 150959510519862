$(document).ready(function () {
    document.addEventListener("DOMContentLoaded", function () {
        var showModalLink = document.getElementById("showModal");
        var modal = document.getElementById("informationCEP");

        showModalLink.addEventListener("mouseover", function () {
            modal.classList.add("show");
            modal.style.display = "block";
        });

        document.addEventListener("click", function (event) {
            if (!modal.contains(event.target) && event.target !== showModalLink) {
                modal.classList.remove("show");
                modal.style.display = "none";
            }
        });

        var botaoLogin = document.querySelector(".btnSignIn");
        var logoutOptionMenu = document.querySelector("#sairMenuLateral");
        var logoutAccountDesktop = document.querySelector("#logoutAccountDesktop");

        var url = window.location.href;
        logoutOptionMenu.style.display = "none";

        if (url == 'https://bjjr-004.dx.commercecloud.salesforce.com/on/demandware.store/Sites-GoPharma-Site/default/Account-Show?registration=false') {
            localStorage.setItem('Usuário', 'Logado');
            console.log(localStorage.getItem('Usuário'))
        }

        if (localStorage.getItem('Usuário') == 'Logado') {
            logoutOptionMenu.style.display = "block";
        }

        logoutOptionMenu.addEventListener("click", function () {
            localStorage.removeItem('Usuário');
        })

        logoutAccountDesktop.addEventListener("click", function () {
            localStorage.removeItem('Usuário');
        })
    });
});

$(document).ready(function () {
    var inputCEP = $("#cepCode");
    var cepValue = $("#cep-value");
    var cepLocation = $("#cep-location");
    var searchButton = $(".search-cep-button");


        inputCEP.on("keydown", function (e) {
            var errorRequisition = true;
            if (e.which == 13) {
                e.preventDefault();

                if (inputCEP.val().length < 9) {
                    var cepError = $("#cep-modal-error");
                    cepError.css("display", "block");
                    cepError.text("Digite um CEP válido. Exemplo: 01234-567");
                } else {
                    var cepError = $("#cep-modal-error");
                    cepError.css("display", "none");
                    var enteredCEP = inputCEP.val();

                    $.ajax({
                        url: "https://viacep.com.br/ws/" + enteredCEP + "/json/",
                        type: "GET",
                        success: function (response) {
                            cepLocation.text(response.localidade + " - " + response.uf);
                            localStorage.setItem('localidade', response.localidade);
                            localStorage.setItem('uf', response.uf);
                            errorRequisition = false;
                            $('#cepSearchButton').trigger('click')
                            $('.user-cep .popover-cep').removeClass('show');
                        },
                        error: function (error) {
                            console.error("Erro ao obter as informações do CEP:", error);
                        }
                    }).done(function(){
                        if(!errorRequisition){
                            var inputCepPdp = $('.search-zipcode-PDP')
                            $.ajax({
                                url: searchButton.data('action'),
                                data: {enteredCEP:enteredCEP, cepLocation: cepLocation.text()},
                                type: "POST",
                                dataType: 'json',
                                success: function (data) {
                                    if(inputCepPdp.length){
                                        inputCepPdp.val(data.enteredCEP);
                                    }
                                },
                                error: function (error) {
                                    console.error("Erro ao obter as informações do CEP:", error);
                                }
                            })
                        }else{
                            cepLocation.text('Informe seu CEP');
                        }
                    });
                }
            }
        });

        searchButton.on("click", function (e) {
            var errorRequisition = true;
            var url= searchButton.data('action');
            e.preventDefault();

            if (inputCEP.val().length < 9) {
                var cepError = $("#cep-modal-error");
                cepError.css("display", "block");
                cepError.text("Digite um CEP válido. Exemplo: 01234-567");
            } else {
                var cepError = $("#cep-modal-error");
                cepError.css("display", "none");
                var enteredCEP = inputCEP.val();
                var cepValue = inputCEP.val()

                $.ajax({
                    url: "https://viacep.com.br/ws/" + enteredCEP + "/json/",
                    type: "GET",
                    success: function (response) {
                        if(response.erro){
                            errorRequisition = true;
                        }else {
                            errorRequisition = false;
                            cepLocation.text(response.localidade + " - " + response.uf);
                            localStorage.setItem('localidade', response.localidade);
                            localStorage.setItem('uf', response.uf);
                            $('.user-cep .popover-cep').removeClass('show');
                        }
                    },
                    error: function (error) {
                        console.error("Erro ao obter as informações do CEP:", error);
                    }
                }).done(function(){
                    if(!errorRequisition){
                        var inputCepPdp = $('.search-zipcode-PDP')
                        $.ajax({
                            url: url,
                            data: {enteredCEP:enteredCEP, cepLocation: cepLocation.text()},
                            type: "POST",
                            dataType: 'json',
                            success: function (data) {
                                if(inputCepPdp.length){
                                    inputCepPdp.val(data.enteredCEP);
                                }
                            },
                            error: function (error) {
                                console.error("Erro ao obter as informações do CEP:", error);
                            }
                        })
                    }else{
                        cepLocation.text('Informe seu CEP');
                    }
                })

            }
        });

});

$('#cepCode').on('input', function () {
    let zipCode = $(this).val().replace(/\D/g, "");
    if (zipCode.length > 5) {
        zipCode = zipCode.substring(0, 5) + "-" + zipCode.substring(5, 8);
    }
    $(this).val(zipCode);
});